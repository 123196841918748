<template>

  <!-- Release Notes View -->
  <j-page
    title="Kexy Release Notes"
    class="release-notes"
  >
    <v-col>
      <j-markdown :source="releaseNotes" class="release-notes" />
    </v-col>
  </j-page>
</template>

<script>

export default {
  name: 'ReleaseNotesView',
  // -----------
  // Metadata ==
  // -----------
  metaInfo() {
    return {
      title: 'Release Notes'
    }
  },
  // -----------
  // Computed ==
  // -----------
  computed: {
    releaseNotes () {
      const notes = `
## What is Kexy?

\`Kexy\` is the name of the Ink 19 content management system which you see before you. The word "kexy" is an old word meaning "reedy, straw-like" and was adopted from a website encouraging the adoption of English words that are in danger of becoming obsolete and forgotten. The site this particular word came from is no longer around, but there are others and they are generally a good source for project names.

---

## Release Notes

### Version 1.1.24 - July 20, 2024

**Features**

- New staff wiki

**Enhancements**

- Use slug to match existing publicists when importing opps to prevent errors and re-imports
- Try to find a 'reply-to' email address when importing opps
- Show release dates in the publishing schedule page
- Retirement comments for opportunities are now system comments

---

### Version 1.1.23 - Feb 11, 2024

**Features**

- Add Featured flag to articles

### Version 1.1.22 - Jul 10, 2023

**Features**

- Refactor news feed
    - Publish daily news feed articles at the end of the day
    - Display corresponding images in dailies
    - Publish the last 10 items to a dedicated "Latest News" post

**Enhancements**

- Allow for the editing of article slugs
- Add Festivals and Gallery sections to news feed items

**Bug Fixes**

- Improve image and embed handling when rendering QT to Wordpress

### Version 1.1.21 - Jun 26, 2023

**Bug Fixes**

- Fix comment email delivery

### Version 1.1.20 - Jun 21, 2023

**Enhancements**

- Allow setting a section when boosting a music news item
- Allow setting a kicker for music news items
- Better subscription / unsubscription handling for industry newsletter

**Bug Fixes**

- Sort out caching of opportunities in staff daily newsletter
- Fix pagination for publicists, and add a query match count header



**Infrastructure**

- Set default time zone to 'Eastern Time (US & Canada)'
- Remove copy@ink19.com from comment recipients
- Standardize editors email in email.yml
- Set 24-hour window for staff newsletter opportunities to end at 10 AM EST (instead of midnight UTC)

---

### Version 1.1.19 - May 21, 2023

**Infrastructure**

- Set default time zone to 'Eastern Time (US & Canada)'
- Remove copy@ink19.com from comment recipients
- Standardize editors email in email.yml
- Set 24-hour window for staff newsletter opportunities to end at 10 AM EST (instead of midnight UTC)

---

### Version 1.1.18 - May 21, 2023

**Enhancements**

- Add keywords to music news feed posts via Mastodon reply
- Allow searching of contacts by email
- Opportunities can now be republished, resetting the publication time

---

### Version 1.1.17 - May 13, 2023

**Features**

- Request opportunities on Room 19 by favoriting them

---

### Version 1.1.16 - May 11, 2023

**Features**

- Staff newsletter now includes personalized list of assignments and requests
- Initial implementation of Room 19 news feed

**Bug Fixes**

- Redirect after login works again

---

### Version 1.1.15 - May 3, 2023

**Bug Fixes**

- Fix missing bottom margin on -ba CSS
- Adjust Wordpress token caching to troubleshoot failed republishing issue
- Fix broken quick-edits in Publicists directory
- Fix article scope for industry newsletter to only include published articles

---

### Version 1.1.14 - April 16, 2023

**Bug Fixes**

- Properly report failures to create Room 19 accounts

---

### Version 1.1.13 - April 13, 2023

**Features**

- Publicist management
- Publicist announcements

**Enhancements**

- Draft announcements

---

### Version 1.1.12 - April 5, 2023

**Bug Fixes**

- Allow empty room19_username when creating contacts
- Notify user when republishing an article fails
- Disable editing media when editing an article to prevent unwanted resets

---

### Version 1.1.11 - March 26, 2023

**Features**

- Allow staff to create a Room 19 account from their profile

**Enhancements**

- Show related assignment when editing an article
- Confirm form resets, also fix them so they actually reset

**Bug Fixes**

- Remove debugging code in image cropper
- Link images in publicist newsletter to public website, not staff

---

### Version 1.1.10 - March 4, 2023

**Enhancements**

- Send weekly newsletter as Mailjet campaign

---

### Version 1.1.9 - February 19, 2023

**Features**

- Weekly newsletter for publicists

---

### Version 1.1.8 - February 8, 2023

**Bug Fixes**

- Assignment cancelation emails no longer sent when an article is submitted

---

### Version 1.1.7 - February 6, 2023

**Bug Fixes**

- Password resets fixed
- Proper checking of dates for RSS updates
- Update credentials with missing lists info in production

---

### Version 1.1.6 - February 1, 2023

**Enhancements**

- Show assignee in opp subtitle

**Bug Fixes**

- Retiring opps works again
- Cleaned up logic for unassigning staff and sending notifications
- Fixed logo in email template

---

### Version 1.1.5 - January 28, 2023

**Enhancements**

- Allow admins to cancel assignments and make opps available again
- Allow admins to reassign an article's section
- Room 19 Updates
    - Delay posting opps by three hours to allow creation of featured opps
    - Boost featured opps on ink19_staff timeline
    - Boost items in music_news_feed on music_news timeline if they are favorited or boosted by staff
    - Add blacklisted tags to music news feeds to screen out non-musical content when possible

**Bug Fixes**

- Images no longer missing on articles that were automatically published
- Better display of source tabs (assignment, section, etc.) when composing an article draft
- Fix aliasing (in tools menu) on a contributor's profile

---

### Version 1.1.4 - January 19, 2023

**Enhancements**

- Allow multiple opp import labels for mailboxes
- Allow mapping of opp import labels into a section

---

### Version 1.1.3 - January 10, 2023

**Enhancements**

- Add tags to opps

**Infrastructure**

- Room 19 integration
    - Post news feed to @music_news
    - Post articles to @ink19
    - Post opps to @ink19_opps

---

### Version 1.1.2 - January 3, 2023

**Infrastructure**

- Refactor graphics to generate suitable images for website (1200px + 300px) instead of using the original upload for everything

---

### Version 1.1.1 - October 22, 2022

**Enhancements**

- Embedded media now supports any iframe

**Infrastructure**

- Refactor article policy calculation to be more dynamic

---

### Version 1.1.0 - October 9, 2022

**Features**

- Updated article editing
    - Display banner when an article is checked out
    - Persist editing when an article is checked out
    - Check in articles when leaving the page
    - Consolidate \`Metadata\` and \`Comments\` tabs into \`Details\`
    - Move article text to left column and put preview in a tab
- Add embeded media (eg Spotify, YouTube) to articles via updated Media Manager (formerly Image Manager)

**Enhancements**

- Show unconfirmed opps when creating a new draft
- Better display of error alerts
- Fix opps, staff, and article searches on Safari

**Infrastructure**

- Updated and normalized dialogs to use \`$dialog\` plugin
- Updated page layouts and routing for Announcements, Articles, Sections, and Staff

---

### Version 1.0.5 - September 10, 2022

**Bug Fixes**

- Reset password link is now properly redirecting to profile

---

### Version 1.0.4 - August 20, 2022

**Enhancements**

- Added release/embargo date to opps and articles
- Alerts now use body font consistently
- The draft form for assignments now links to the original opportunity

**Infrastructure**

- Updated page layouts and routing for Opportunities, Assignments, and Drafts

---

### Version 1.0.3 - July 26, 2022

**Features**

- Add functionality to allow the creation of news items from opportunities

**Enhancements**

- Long page titles are truncated to prevent them from wrapping around and extending the header vertically
- Hide draft notice when editing a draft
- Add option to allow for the creation of another draft in the same section to the Article Tools menu

---

### Version 1.0.2 - July 26, 2022

**Enhancements**

- Divided sources for new drafts into tabs
- Added support for creating news articles in Draft page
- List news sections in section directory

---

### Version 1.0.1 - July 7, 2022

**Enhancements**

- Opp preview now shows for recommendations
- Allow editors to assign an opp when making a recommendation
- Staff directory now shows a masthead with editors and such
- Emails generated by the system have been updated and generally made more presentable

**Bug Fixes**

- Selecting an image as a feature image properly locks down the cropper to the required proportions / size
- Complete section descriptions are now being shown in the selection list when creating a draft

---

### Version 1.0.0 - June 27, 2022

Benchmark release. Kexy has been in production for a while now, but this is the point at which we began tracking a version and updating these release notes.
      `.trim()
      return notes
    }
  }
}
</script>

<style lang="scss">

.release-notes {
  hr {
    margin: 1.5rem 0;
    border: 1px dotted $primary-color;
  }
  h1 {
    @extend %j-heading-1;
    margin-bottom: 0.5em;
  }
  h2 {
    @extend %j-heading-2;
    margin-bottom: 0.5em;
  }
  h3 {
    @extend %j-heading-3;
    margin-bottom: 0.5em;
  }
  p, ul, ol {
    @extend %j-body;
    margin-bottom: 0.5em;
  }
  a {
    text-decoration: underline;
  }
  table {
    width: 100%;
    border: 1px solid $secondary-color;
    @extend %j-body;
    th {
      text-align: left;
      padding: 4px;
      border-bottom: 1px solid $secondary-color;
      @extend %j-label;
    }
    td {
      padding: 4px;
      border-bottom: 1px solid $secondary-color;
    }
    tr:last-child {
      td {
        border-bottom: none;
      }
    }
  }
}

</style>